* {
    outline: none !important;
}

.magazine {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    max-height: 100%;
    text-align: center;
    .turn-page {
        background-color: #ccc;
        background-size: 100% 100%;
    }
    &-conteint {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &-image {
        background: #fff;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        display: inline-block;
    }
    &-image-size {
        width: auto;
        height: 100%;
        position: absolute;
        visibility: hidden;
        &-mobile {
            width: auto;
            height: auto;
            max-height: 100vh;
            max-width: 100vw;
            display: block;
            margin: 0 auto;
        }
    }
    &-control {
        position: absolute;
        top: 50%;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #00000050;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all ease 0.2s;
        z-index: 9;
        &-left {
            left: 15px;
        }
        &-right {
            right: 15px;
        }
        span {
            font-size: 40px;
        }
        &:hover {
            background-color: #00000090;
        }
        &-zoom {
            position: absolute;
            top: unset;
            bottom: 15px;
            right: 15px;
            display: flex;
            .magazine-control {
                position: relative;
                margin: 0 5px;
            }
        }
    }
    &-fullscreen {
        position: absolute;
        top: 15px;
        right: 15px;
    }
    &-link {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none !important;
        span {
            color: #ffffff50 !important;
            text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
            font-size: 30px;
            transition: all ease 0.2s;
        }
        &:hover {
            span {
                color: #ffffff !important;
                font-size: 40px;
            }
        }
    }
}
  
@media (max-width: 769px) {
    .magazine-control-left,
    .magazine-control-right {
        display: none;
    }
    .magazine-control-zoom {
        display: none;
    }
}