.shape {
    background: rgba(0, 123, 255, 0.314);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(0, 123, 255);
    position: absolute;
    cursor: pointer;

    &.circle {
        border-radius: 50%;
    }
    &.triangle {
        border-color: transparent transparent red transparent;
        border-style: solid;
        border-width: 0px 200px 200px 200px;
        height: 0px;
        width: 0px;
    }
    &-popover {
        background: #fff;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 100%;
        margin-left: -8px;
        min-width: 159px;
        padding: 10px;
        border-radius: 5px;
        opacity: 0;
        transition: all ease 0.2s;
        &.show {
            margin-left: 8px;
            opacity: 1;
        }
    }
    &-buttons {
        position: absolute;
        right: -50px;
        top: 0;
        width: 50px;
        padding-left: 20px;
    }
    &-button {
        width: 40px;
        height: 40px;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        margin-bottom: 10px;
        margin-left: -12px;
        position: relative;
        &:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
        }
        span {
            display: flex;
            width: 40px;
            height: 40px;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
        }
    }
    &-area {
        width: 100%;
        height: 100%;
    }
}