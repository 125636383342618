body, #root {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.editor {
    display: flex;
    height: calc(100% - 55px);
    &-sidebar {
        width: 200px;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        padding: 15px;
        border-right: 1px solid rgba(0, 0, 0, 0.1);

    }
    &-sidebar &-image{
        width: 100%;
        height: auto;
        background-size: 100%;
        background-position: center;
        border: 1px solid rgba(0, 0, 0, 0.1);
        transition: all ease 0.1s;
        cursor: pointer;
        &:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            background-size: 120%;
        }
        &-box {
            position: relative;
            margin-bottom: 10px;
        }
        &-counter,
        &-delete {
            width: 23px;
            height: 23px;
            border-radius: 50%;
            background-color: #057bfe;
            color: #fff;
            counter-increment: section;
            content: counter(section);
            position: absolute;
            top: 5px;
            left: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
        }
        &-delete {
            left: unset;
            right: 5px;
            background-color: #dc3545;
            cursor: pointer;
            span {
                font-size: 18px;
            }
        }
    }
    &-sidebar &-image-box.active &-image {
        border-color: #057bfe;
        border-width: 2px;
    }
    &-sidebar &-image + &-image {
        margin-top: 10px;
    }
    &-content {
        width: 100%;
        height: 100%;
        background-color: #00000050;
        display: flex;
        flex-direction: column;
    }
    &-toolbars {
        width: 100%;
        height: 50px;
        padding: 5px 0;
        background-color: #fff;
        display: flex;
        &-col {
            display: flex;
        }
        &-group {
            display: flex;
            padding: 0 7px;
        }
        &-group + &-group {
            border-left: 1px solid #ccc;
        }
        &-button {
            padding: 10px;
            border-radius: 5px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 1px;
            cursor: pointer;
            transition: all ease 0.2s;
            span {
                font-size: 20px;
            }
            &:hover,
            &.active {
                background-color: #ddd;
            }
        }
    }
    &-window {
        height: calc(100% - 50px);
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        // position: relative;
        &-block {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    &-window &-image {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        display: block;
        margin: 0 auto;
        &-area {
            position: relative;
            display: inline-block;
            height: 100%;
            width: auto;
        }
    }
    &-dragdrop {
        width: 100%;
        height: 300px;
        
        
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #555;
        span {
            font-size: 100px;
        }
        &.on {
            background-color: rgba(#007bff, 0.2);
            border: 1px solid #007bff;
        }
    }
}